
import React from 'react'
import PropTypes from 'prop-types'
import { useContext, createContext, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Config from 'src/configurations/Config'
import ApiClient from 'src/utils/api/ApiClient'
import loadingImg from "../assets/images/avatars/loading.gif"
// import { Exception } from 'sass'

const AuthContext = createContext()

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState({
    fullName: "",
    email: "",
    phoneNumber: "",
    profilePicturePath: loadingImg,
    userCompanyId: 0,
    userCompany: {
      id: 0,
      companyName: "",
      description: "",
      claimedEmployeeCount: 0,
      email: "",
      founder: "",
      vergiDairesi: "",
      vergiNumarası: "",
      adres: "",
      phoneNumber: 0
    }
  });
  var token = JSON.parse(localStorage.getItem('token')) || ''
  const navigate = useNavigate()
  const [isLoggedIn, setIsLoggedIn] = useState(localStorage.getItem('token') !== null);

  const [lastCheck, setLastCheck] = useState(new Date());

  const CheckAuth = async () => {

    // eğer token yoksa kafadan false et
    if (!token)
      return setIsLoggedIn(false);

    var seconds = (new Date().getTime() - lastCheck.getTime()) / 1000;

    if (seconds >= 10) {
      var response = await ApiClient.get(Config.ApiClient.EndPoints.User.CheckAuth)

      setLastCheck(new Date());

      if (response?.status === 200) {
        setIsLoggedIn(true)
      } else {
        setIsLoggedIn(false);
        logOut("Oturum Sonlandı. Lütfen Tekrar Giriş Yapın");
      }

    } else {
      setIsLoggedIn(true);
    }
  };


  // useEffect(() => {
  //   CheckAuth();
  //   GetUser();
  // });

  const GetUser = async () => {
    if (user.fullName === '') {
      //userı alalım
      const response = await ApiClient.get(Config.ApiClient.EndPoints.User.GetUser);
      if (response?.data) {
        response.dataprofilePicturePath = Config.ApiClient.BaseUrl + response.dataprofilePicturePath
        setUser(response.data);
      }
    }
  }



  const loginAction = async (data) => {
    var response;
    try {
      response = await ApiClient.post(Config.ApiClient.EndPoints.User.Login, JSON.stringify(data), {})
      // const res = await response.json()

      if (response?.data) {

        token = response.data
        localStorage.setItem('token', JSON.stringify(response.data))
        //set header for axios Instance (api client)
        var jwt = "Bearer " + response.data.token;

        ApiClient.defaults.headers.common['Authorization'] = jwt;
        setIsLoggedIn(true);
        // <Navigate to="/dashboard" />
        navigate("/dashboard")
        return
      }
    } catch (err) {
      console.error(err)
    }

  }

  const logOut = (errorMessage) => {

    setIsLoggedIn(false);
    token = ''
    localStorage.removeItem('token')
    var url = errorMessage === undefined ? "/Login" : "/Login?error=" + errorMessage;

    navigate(url);
    // <Navigate to={url} />
  }

  const registerAction = async (data) => {
    var response;
    try {
      response = await ApiClient.post(Config.ApiClient.EndPoints.User.Register, JSON.stringify(data), {})
      // const res = await response.json()

      if (response?.data) {


        // <Navigate to="/dashboard" />
        // navigate("/login")
        return loginAction({
          email: data.email,
          password: data.password,
          rememberMe: true,
        })
      }
    } catch (err) {
      console.error(err)
    }

  }

  return (
    <AuthContext.Provider value={{ token, user, isLoggedIn, loginAction, logOut, setUser, CheckAuth, GetUser, registerAction }}>
      {children}
    </AuthContext.Provider>
  )
}

export default AuthProvider

export const useAuth = () => {
  return useContext(AuthContext)
}

AuthProvider.propTypes = {
  children: PropTypes.any,
}
