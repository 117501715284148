import React, { useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "./AuthProvider";

const PrivateRoute = () => {
  const auth = useAuth();
  // useEffect(()=>{
  //   console.log("checkAuth!!!")
  //   auth.CheckAuth();
  // });

  var url = window.location.href;
  // console.log(url);
  if
    (
    url.includes("/login")
    ||
    url.includes("/register")
  )
    return <Outlet />;

  if (!auth.isLoggedIn) {
    // console.log("privateRoute Nav...")
    return <Navigate to="/login" />;
  }
  return <Outlet />;
};

export default PrivateRoute;
