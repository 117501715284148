import React, { Component, Suspense, useEffect } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import './scss/style.scss'
import AuthProvider from './auth/AuthProvider'
import PrivateRoute from './auth/PrivateRoute'
import LoadingSpinner from './components/LoadingSpinner'
import * as Sentry from "@sentry/react";
import Config from './configurations/Config'

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

if (Config.useSentry) {
  Sentry.init({
    dsn: "https://a06ed135acda4cb68d1edef02a55f086@o4504830197432320.ingest.sentry.io/4504830214537216",
    integrations: [
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
        maskAllInputs: false,
      }),
    ],
    // Session Replay
    replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
  console.log("sentry aktif");
} else {
  console.log("sentry devre dışı");
}
class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <Suspense fallback={<LoadingSpinner></LoadingSpinner>}>
          <AuthProvider>
            <Routes>
              <Route exact path="/login" name="Login Page" element={<Login />} />
              <Route exact path="/register" name="Register Page" element={<Register />} />
              <Route exact path="/404" name="Page 404" element={<Page404 />} />
              <Route exact path="/500" name="Page 500" element={<Page500 />} />
              <Route element={<PrivateRoute />}>
                <Route path="*" name="Home" element={<DefaultLayout />} />
              </Route>
            </Routes>
          </AuthProvider>
        </Suspense>
      </BrowserRouter>
    )
  }
}

export default App
