import React from 'react'
import PropTypes from 'prop-types';
import CIcon from '@coreui/icons-react'
import { logoNegative } from 'src/assets/brand/logo-negative'

const LoadingSpinner = (props) => {
  return (
    <div id="loader" className={props.className ? props.className : ''} style={props.style ? props.style : null}>
      <div>
        <div style={{
          position: "relative",
          zIndex: "unset",
          width: '100px',
          top: '92px',
          left: '27px'
        }}>
          <CIcon icon={logoNegative} width={110} alt="Logo" className="ml-5 pl-5" />
        </div>
        <div className="spins">
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  )
}

export default React.memo(LoadingSpinner)

LoadingSpinner.propTypes = {
  className: PropTypes.string,
  alt: PropTypes.string,
  style: PropTypes.any,
  // Other prop validations can continue from here
};
