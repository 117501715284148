export const logoNegative = [
  '160 45',
  `
  <title>SGK Kaşif Logo</title>
  <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="93.815" y1="63.5619" x2="47.2397" y2="-17.1089">
	<stop  offset="0" style="stop-color:#4856A6"/>
	<stop  offset="0.3038" style="stop-color:#fff"/>
	<stop  offset="0.4832" style="stop-color:#fff"/>
	<stop  offset="0.5432" style="stop-color:#fff"/>
	<stop  offset="0.6268" style="stop-color:#fff"/>
	<stop  offset="0.7245" style="stop-color:#fff"/>
	<stop  offset="0.8327" style="stop-color:#fff"/>
	<stop  offset="0.9482" style="stop-color:#fff"/>
	<stop  offset="1" style="stop-color:#fff"/>
</linearGradient>
<path class="st0" d="M125.4,26.36l7.86,14.43h-2.74l-0.05-0.09l-6.44-11.83L122.87,31l5.33,9.79h-2.74l-3.96-7.27l-3.96,7.27h-2.66
	l15.63-28.86h2.74L125.4,26.36z M105.68,29.46h6.7v8.97l-0.07,0.05c-0.44,0.32-1.44,0.88-2.34,1.23c-2.56,1.01-5.2,1.06-5.7,1.06
	c-4.46,0-8.57-2.02-11.32-5.54c-0.87,2.48-3.25,5.34-9.6,5.55c-0.2,0.01-0.41,0.01-0.61,0.01c-6.25,0-9.79-3.37-10.81-5.21
	l-0.07-0.13l2.15-1.46l0.09,0.13c1.24,1.81,2.99,3.71,6.58,4.14c3.61,0.43,6.62-0.24,8.47-1.89c1.14-1.01,1.76-2.32,1.76-3.69
	c0-4.22-4.29-5.88-6.85-6.87l-0.04-0.01c-1-0.35-5.88-2.15-6.43-4.4c-0.42-1.71,0.04-3.35,1.19-4.29c1.29-1.04,3.29-1.15,4.6-0.92
	c2.83,0.48,4.41,2.39,4.95,3.19c0.62,0.91,1.8,3.79,2.93,6.57c0.7,1.73,1.37,3.36,1.86,4.41c1.69,4.7,6.17,7.85,11.13,7.85
	c2.11,0,3.81-0.37,5.48-1.21v-2c-0.69,0.47-2.58,1.56-5.48,1.56c-3.64,0-7.01-1.95-8.83-5.11l-0.01,0.01l-0.07-0.15
	c-0.16-0.3-1.06-2.35-2.09-4.71c-0.92-2.11-1.96-4.49-2.7-6.11c-1.53-3.38-4.15-5.38-7.57-5.8c-2.12-0.26-4.13,0.29-5.5,1.51
	c-1.15,1.02-1.75,2.4-1.75,4c0,4.02,4.22,5.6,7.3,6.75c0.29,0.11,0.58,0.22,0.85,0.32l0.37,0.14c1.69,0.61,4.75,2.11,4.8,4.97
	c0.01,0.72-0.14,2.08-1.28,3.16c-1.23,1.16-3.23,1.65-5.98,1.47c-2.42-0.17-5.28-1.86-6.5-3.85l-0.08-0.14l2.01-1.3l0.09,0.12
	c1.4,1.78,3.29,2.75,5.62,2.87c1.4,0.07,2.41-0.18,3-0.75c0.27-0.25,0.59-0.71,0.58-1.44c0-1.52-2.3-2.4-4.73-3.33
	c-1.8-0.69-3.66-1.4-5.02-2.46c-1.62-1.26-3.55-3.39-3.55-6.35c0-2.38,1.02-4.73,2.73-6.28c1.85-1.68,4.44-2.41,7.47-2.11
	c0.85,0.01,5.31,0.36,8.89,6.45c2.68-4.05,7.13-6.45,11.96-6.45c4.52,0,8.69,2.06,11.44,5.66l0.1,0.13l-0.13,0.1
	c-0.5,0.37-1.13,0.83-1.79,1.33l-0.13,0.1l-0.1-0.13c-2.27-2.95-5.69-4.64-9.39-4.64c-4.54,0-8.6,2.54-10.61,6.62
	c0.32,0.75,0.6,1.5,0.86,2.29c1.28-4.3,5.22-7.26,9.75-7.26c3.17,0,6.11,1.45,8.06,3.98l0.1,0.13l-2.14,1.6l-0.1-0.13
	c-1.44-1.85-3.6-2.9-5.93-2.9c-4.14,0-7.52,3.37-7.52,7.52c0,1,0.19,1.97,0.58,2.89l0.46,0.91c1.34,2.29,3.83,3.72,6.48,3.72
	c1.77,0,3.58-0.69,4.91-1.85h-3.49V29.46z M85.16,23.6c1.34,0.52,2.61,1.01,3.54,1.55c-0.8-1.69-1.75-3.27-2.83-4.7
	c-0.75-0.99-2.39-1.71-3.72-1.71c-0.22,0-0.43,0.02-0.63,0.06c-0.78,0.16-1.27,0.63-1.45,1.37C79.74,21.5,82.49,22.56,85.16,23.6z
	 M116.38,34.65l12.24-22.48l0.13-0.24h-2.74l-0.05,0.09c-0.08,0.16-7.81,14.36-9.57,17.58v-4.39h-10.72v2.59h8.08v9.62l0.27-0.22
	C115.37,36.12,116.33,34.72,116.38,34.65z M31.76,28.93c0,0.02,0,0.04,0,0.07v12.39c0,0.81,2.01,2.82,2.83,2.82
	c0.01,0,0.02,0,0.03,0c0.78-0.01,2.83-2.01,2.83-2.82V29c0-0.02,0-0.04,0-0.07c0.01-1.27,0.26-2.48,0.74-3.62
	c0.5-1.16,1.18-2.17,2.03-3.03c0.84-0.86,1.85-1.54,3.01-2.05c1.16-0.51,2.41-0.76,3.74-0.76c1.33,0,2.56,0.25,3.72,0.76
	c1.16,0.51,2.17,1.2,3.03,2.07c0.86,0.86,1.54,1.87,2.04,3.02c0.49,1.15,0.74,2.38,0.74,3.68c0,1.31-0.25,2.53-0.74,3.68
	c-0.5,1.15-1.18,2.16-2.04,3.03c-0.86,0.87-1.87,1.56-3.03,2.07c-1.16,0.5-2.39,0.76-3.72,0.76h-2.68c-1.8,0-2.81,2.67-2.81,2.67
	s0.86,3,2.81,3h2.68c2.09,0,4.01-0.37,5.8-1.1c1.78-0.73,3.43-1.84,4.95-3.35c2.97-2.94,4.46-6.53,4.46-10.76
	c0-2.09-0.37-4.05-1.11-5.85c-0.58-1.39-1.37-2.68-2.39-3.87c4.18-1.48,7.26-4.76,9.25-9.85c-3.09,3.23-9.69,4.7-19.78,4.41
	c-0.39-0.03-0.78-0.04-1.18-0.04c-4.2,0-7.79,1.48-10.75,4.45c-0.6,0.6-1.14,1.23-1.62,1.88c-0.48-0.65-1.02-1.28-1.62-1.88
	c-2.96-2.97-6.55-4.45-10.75-4.45c-0.4,0-0.79,0.01-1.18,0.04C10.96,14.13,4.37,12.66,1.27,9.43c1.98,5.09,5.06,8.37,9.25,9.85
	c-1.01,1.19-1.81,2.48-2.39,3.87c-0.74,1.8-1.11,3.75-1.11,5.85c0,4.24,1.49,7.82,4.46,10.76c1.52,1.5,3.17,2.61,4.95,3.35
	c1.79,0.73,3.72,1.1,5.8,1.1h2.68c1.72,0,2.79-2.48,2.79-2.48s-0.76-3.19-2.79-3.19h-2.68c-1.33,0-2.56-0.26-3.72-0.76
	c-1.16-0.51-2.17-1.2-3.03-2.07c-0.86-0.87-1.54-1.88-2.04-3.03c-0.49-1.15-0.74-2.37-0.74-3.68c0-1.3,0.25-2.53,0.74-3.68
	c0.5-1.15,1.18-2.16,2.04-3.02c0.86-0.87,1.87-1.56,3.03-2.07c1.16-0.51,2.39-0.76,3.72-0.76c1.33,0,2.59,0.25,3.74,0.76
	c1.16,0.51,2.17,1.19,3.01,2.05c0.85,0.86,1.53,1.87,2.03,3.03C31.5,26.45,31.75,27.66,31.76,28.93z M28.04,31.43
	c-0.33,0.76-0.78,1.43-1.35,2c-0.57,0.58-1.24,1.03-2,1.37c-0.77,0.33-1.58,0.5-2.46,0.5c-0.88,0-1.71-0.17-2.48-0.5
	c-0.77-0.34-1.43-0.79-1.99-1.36c-0.56-0.56-1.01-1.23-1.34-2c-0.32-0.77-0.49-1.58-0.49-2.44c0-0.86,0.17-1.67,0.49-2.44
	c0.33-0.77,0.78-1.43,1.34-2c0.56-0.57,1.23-1.02,1.99-1.35c0.77-0.34,1.59-0.5,2.48-0.5c0.88,0,1.69,0.16,2.46,0.5
	c0.77,0.34,1.43,0.79,2,1.37c0.57,0.57,1.02,1.24,1.35,2c0.33,0.76,0.49,1.58,0.49,2.43C28.53,29.87,28.36,30.68,28.04,31.43
	L28.04,31.43z M40.67,29c0-0.86,0.17-1.67,0.49-2.43c0.33-0.76,0.78-1.43,1.35-2c0.57-0.58,1.24-1.03,2-1.37
	c0.77-0.34,1.58-0.5,2.46-0.5c0.88,0,1.71,0.17,2.47,0.5c0.77,0.34,1.43,0.78,1.99,1.35c0.56,0.57,1.01,1.24,1.34,2
	c0.32,0.77,0.49,1.58,0.49,2.44c0,0.86-0.17,1.68-0.49,2.44c-0.33,0.77-0.78,1.43-1.34,2c-0.56,0.57-1.23,1.02-1.99,1.36
	c-0.77,0.33-1.59,0.5-2.47,0.5c-0.88,0-1.69-0.17-2.46-0.5c-0.77-0.34-1.43-0.79-2-1.37c-0.57-0.58-1.02-1.24-1.35-2
	C40.83,30.68,40.67,29.87,40.67,29L40.67,29z M44.97,29.84c0.11,0.26,0.27,0.49,0.46,0.69c0.2,0.2,0.43,0.35,0.69,0.47
	c0.26,0.11,0.54,0.17,0.85,0.17c0.3,0,0.59-0.06,0.85-0.17c0.26-0.12,0.49-0.27,0.69-0.47c0.19-0.19,0.35-0.42,0.46-0.69
	c0.11-0.26,0.17-0.54,0.17-0.84c0-0.3-0.06-0.58-0.17-0.84c-0.11-0.26-0.27-0.49-0.46-0.69c-0.19-0.2-0.42-0.35-0.69-0.47
	c-0.26-0.12-0.55-0.17-0.85-0.17c-0.3,0-0.58,0.06-0.85,0.17c-0.26,0.12-0.49,0.27-0.69,0.47c-0.2,0.2-0.35,0.43-0.46,0.69
	c-0.11,0.26-0.17,0.54-0.17,0.84C44.8,29.3,44.85,29.58,44.97,29.84L44.97,29.84z M23.08,27.01c-0.26-0.12-0.54-0.17-0.85-0.17
	c-0.3,0-0.59,0.06-0.85,0.17c-0.26,0.12-0.49,0.27-0.69,0.47c-0.19,0.2-0.35,0.43-0.46,0.69c-0.11,0.26-0.17,0.54-0.17,0.84
	c0,0.3,0.06,0.58,0.17,0.84c0.11,0.26,0.27,0.49,0.46,0.69c0.19,0.2,0.42,0.35,0.69,0.47c0.26,0.11,0.55,0.17,0.85,0.17
	c0.3,0,0.58-0.06,0.85-0.17c0.26-0.12,0.49-0.27,0.69-0.47c0.2-0.2,0.35-0.43,0.46-0.69c0.11-0.26,0.17-0.54,0.17-0.84
	c0-0.3-0.06-0.58-0.17-0.84c-0.11-0.26-0.27-0.49-0.46-0.69C23.57,27.28,23.34,27.12,23.08,27.01z M49.31,6.54
	c-10.21,1.68-14.72,7.49-14.72,7.49s-4.49-5.81-14.7-7.49l-3.29,2.99c0,0,9.79-0.18,18,7.37C42.81,9.34,52.6,9.52,52.6,9.52
	L49.31,6.54z M42.77,3.01c0,0-3.08,1.14-8.17,5.47c-5.09-4.33-8.17-5.46-8.17-5.46l-4.13,2.09c7.34,1.28,12.3,5.98,12.3,5.98
	s4.94-4.71,12.29-5.98L42.77,3.01z M34.6,5.56c2.49-2.05,5.06-3.51,5.06-3.51c-1.82-0.75-8.28-0.76-10.13,0
	C29.53,2.05,32.1,3.51,34.6,5.56L34.6,5.56z"/>
`,
]
